<template>
  <error-page
    code="403"
    desc="抱歉，你无权访问该页面"
    :src="src"
  />
</template>

<script>
import error403 from "@/assets/images/error/error-401.svg";
import errorPage from "./module/error-page";
export default {
  components: {
    errorPage,
  },
  data() {
    return {
      src: error403,
    };
  },
}
</script>

<style>
</style>
